import React from "react";
import { isMobile } from "react-device-detect";

export default function Whatsapp() {
  return (
    <>
      <div className="wh-widget-send-button-desktop">
        <div
          id="wh-widget-send-button-wrapper"
          className="wh-widget-send-button-wrapper wh-widget-right"
        >
          <div id="popup-placement" className="popup-slide"></div>
          <div
            id="wh-call-to-action"
            className=" wh-animation-in"
            wh-click="clickOnCallToAction"
            style={{ marginBottom: "-60px", marginRight: "80px" }}
          >
            <a wh-href="href" wh-target="target" href="#!">
              <div className="wh-call-to-action-content" wh-html-unsafe="text">
                Whatsapp
              </div>
            </a>
          </div>
          <div
            className="wh-widget-hello-popup-wrapper wh-popup-right wh-hide popup-slide popup-slide-in"
            id="wh-popup-hello"
          >
            <div className="wh-widget-hello-popup">
              <div
                className="wh-widget-hello-popup-close"
                wh-click="closeHelloPopup"
              >
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </div>
              <div className="wh-widget-hello-popup-content">
                <div className="wh-widget-hello-popup-content-logo"></div>
                <div
                  className="wh-widget-hello-popup-content-text"
                  wh-click="showMessengers"
                >
                  <a wh-href="href" wh-target="target">
                    <div wh-html-br="text"></div>
                  </a>
                </div>
                <div className="wh-clear"></div>
              </div>

              <div
                className="wh-messengers wh-hide"
                wh-multi-class="{'wh-hide': '!showMessengersIcons'}"
              >
                <ul wh-html-element="buttons"></ul>
              </div>
            </div>
          </div>
          <div id="popup-placement2" className="popup-slide"></div>
          <div
            id="wh-widget-send-button-wrapper-list"
            className=" wh-widget-send-button-wrapper-list"
          >
            <a
              className=" wh-widget-button wh-widget-button-activator mobile_view"
              href="https://api.whatsapp.com/send?phone=+919686370242&amp;text=Hi"
              target="_blank"
            >
              <div className=" wh-widget-button-icon wh-messenger-bg-whatsapp">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="-9 -10 41 44"
                    className="wh-messenger-svg-close wh-svg-close"
                  >
                    <path
                      d=" M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 32 32"
                    className="wh-messenger-svg-whatsapp wh-svg-icon"
                  >
                    <path
                      d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
            </a>
            <a
              className=" wh-widget-button wh-widget-button-activator desktop_view"
              href="https://web.whatsapp.com/send?phone=+919686370242&text=Hi"
              target="_blank"
            >
              <div className=" wh-widget-button-icon wh-messenger-bg-whatsapp">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="-9 -10 41 44"
                    className="wh-messenger-svg-close wh-svg-close"
                  >
                    <path
                      d=" M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 32 32"
                    className="wh-messenger-svg-whatsapp wh-svg-icon"
                  >
                    <path
                      d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
